$interBlue: #232c41;
$interbeige: #d0a17c;
$interBrown: #e5cbb6;

@font-face {
  font-family: Caslon;
  src: url(/assets/fonts/Caslon.ttf);
}

@font-face {
  font-family: Gotham;
  src: url(/assets/fonts/Gotham\Medium\Regular.ttf);
}

@font-face {
  font-family: GothamLight;
  src: url(/assets/fonts/GothamLight.otf);
}

h1,
h4 {
  margin: 0;
  font-family: Caslon;
}

h5 {
  font-size: 18px;
  margin: 0;
  margin-bottom: 15px;
}

.error-msg {
  color: #ea5959;
}

p {
  padding: 0;
  margin: 0;
  font-family: GothamLight;
  line-height: 20px;
  font-size: 14px;
}
body {
  font-family: Gotham;
  color: $interBlue;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Lato', sans-serif;
  background-color: #fff;
  position: relative;
}

.content {
  display: flex;
  align-items: center;

  .device-row {
    position: absolute;
    top: 161px;
    right: 1px;
    width: 50%;
    height: 1220px;
    overflow: hidden;

    .device {
      background-image: url(/assets/images/INTERFACE_DEVICE_NAVY.png);
      height: 479px;
      width: 515px;
      position: absolute;
      bottom: -112px;
      right: -210px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: 50%;
      z-index: -1;
    }
  }

  .page {
    width: 100%;
    display: flex;
    flex-direction: column;
    min-height: 781px;

    .inner-container {
      display: flex;
      flex-direction: row;
      .left-col {
        flex: 1;
        margin-top: 150px;
        .banner-img {
          background-image: url('/assets/images/banner.jpg');
          width: 100%;
          background-size: cover;
          height: 100%;
          background-position: center;
          min-height: 1270px;
        }
      }
      .right-col {
        flex: 3;
      }
    }

    .title-row {
      .inner {
        padding: 0px 50px;
      }

      text-align: center;
      background-color: $interBlue;
      height: 150px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      position: absolute;
      top: 0;
      width: 100vw;
      left: 0;

      .logo {
        background-image: url(/assets/images/INTERFACE_LOGO_PRIMARY.png);
        height: 84px;
        background-repeat: no-repeat;
        background-size: contain;
        width: 290px;
      }
      h1 {
        margin: 25px 0px;
      }
    }

    .copy-row {
      display: flex;
      margin-top: 139px;
      position: relative;
      flex-direction: column;

      .copy-left-col {
        flex: 1;
        padding: 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 50px 0px 0px 0px;
        text-align: center;
      }

      .copy-right-col {
        flex: 1;
        padding: 50px;
        min-height: 545px;
        display: flex;
        text-align: center;
        flex-direction: column;
        width: 600px;
        max-width: 600px;
        margin: auto;

        ul {
          display: flex;
          justify-content: space-around;
          padding: 0;
          width: 100%;
          list-style: none;
          font-weight: bolder;
          li {
            display: flex;
            font-size: 14px;
            max-width: 223px;
            span {
              color: $interbeige;
              margin-right: 10px;
            }
            p {
              color: #444;
              font-family: Lato, sans-serif;
            }
            a {
              text-decoration: none;
              color: #444;
            }
          }
        }
        #landing-form {
          .form-row {
            margin-bottom: 15px;
            input {
              height: 35px;
              padding: 0 10px;
              width: 100%;
              background-color: #cacaca52;
              border: 0;
              font-size: 14px;
              font-family: GothamLight;
            }
            textarea {
              width: 100%;
              padding: 10px;
              background-color: #cacaca52;
              border: 0;
              font-size: 14px;
              font-family: GothamLight;
            }
          }
          input.submit {
            background-color: $interBlue;
            color: $interBrown;
            width: 160px;
            height: 40px;
            border: 1px solid transparent;
            transition: all 0.4s ease;
            font-size: 14px;
            font-family: GothamLight;
            &:hover {
              border: 1px solid $interBlue;
              background-color: transparent;
              color: $interbeige;
            }
          }
        }
      }

      .sub-title-row {
        .line {
          width: 50px;
          height: 3px;
          background-color: $interBlue;
          margin-bottom: 25px;
        }
      }

      .sub-title-row h4 {
        margin-bottom: 15px;
        color: $interbeige;
        font-size: 34px;
        letter-spacing: 1px;
      }

      .copy-container {
        margin-bottom: 50px;
      }
      .copy-container:last-child {
        margin: 0;
      }
      .copy-container {
        p {
          margin-bottom: 15px;
        }
        ul {
          list-style: none;
          padding-left: 20px;
          li {
            font-family: GothamLight;
            font-size: 14px;
            &::before {
              content: '\2022'; /* Add content: \2022 is the CSS Code/unicode for a bullet */
              color: $interBrown; /* Change the color */
              width: 1em; /* Also needed for space (tweak if needed) */
              margin-left: -1em; /* Also needed for space (tweak if needed) */
              margin-right: 10px;
            }
            margin-bottom: 5px;
          }
        }
      }

      .copy-container p {
        margin-bottom: 10px;
      }
    }

    .footer-row {
      display: flex;
      justify-content: flex-end;
      height: 50px;
      position: absolute;
      width: 100%;
      bottom: 0;
      left: 0;
      background-color: $interBlue;
      ul {
        display: flex;
        justify-content: space-around;
        padding: 0;
        width: 100%;
        align-items: flex-start;
        list-style: none;
        width: 600px;
        li {
          display: flex;
          font-size: 14px;
          max-width: 255px;
          span {
            color: $interbeige;
            margin-right: 10px;
          }
          p {
            color: #fff;
          }
          a {
            text-decoration: none;
            color: #fff;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 900px) {
  body {
    padding: 0px 25px;
    position: relative;
  }

  .content .page .copy-row .right-col,
  .content .page .copy-row .left-col {
    padding: 25px;
  }

  .content .page .copy-row .right-col ul {
    flex-direction: column;
    align-items: center;
  }

  .content .page .copy-row .right-col ul li {
    text-align: center;
    padding: 10px 0px;
  }

  .content .page .copy-row .right-col ul li.desktop {
    display: none;
  }

  .content .page .copy-row {
    max-width: 400px;
  }

  .content .page .copy-row .copy-left-col {
    padding: 50px 0px;
  }

  .content .page .copy-row .copy-right-col ul {
    flex-direction: column;
  }

  .content .page .copy-row .copy-right-col {
    max-width: 300px;
    padding: 0;
  }
  .content .page .footer-row ul {
    flex-direction: column;
  }

  .content .page .footer-row {
    height: initial;
    padding: 15px 50px;
    width: calc(100vw - 100px);
  }

  .content .page .footer-row ul li {
    margin-bottom: 10px;
  }

  .content .page .footer-row ul li:last-child {
    margin-bottom: 0;
  }

  li.desktop {
    display: none !important;
  }

  .device-row {
    display: none;
  }

  .content .page .copy-row .copy-container:nth-child(2) {
    margin-bottom: 65px;
  }

  .content .page .copy-row .copy-container:nth-child(4) {
    margin-bottom: 0px;
  }

  .content .page .copy-row .left-col,
  .content .page .copy-row .right-col {
    justify-content: initial;
  }

  .content .page .copy-row .right-col {
    margin-bottom: 85px;
  }

  .copy-row {
    flex-direction: column;
  }

  .page {
    max-height: initial;
  }

  .content {
    height: initial;
  }

  .left-col,
  .right-col {
    padding: 25px 0px;
  }

  .content .page .copy-row .left-col {
    margin: 65px 0px 0px 0px;
    padding: 25px;
  }

  .content .page .title-row .logo {
    margin: auto;
  }

  .content .page .inner-container .left-col {
    display: none;
  }
}

@media only screen and (min-width: 901px) {
  li.mobile {
    display: none !important;
  }
}

@media only screen and (min-width: 1350px) {
  .content .page .copy-row .copy-container p {
    padding: 0px 175px;
  }
}
